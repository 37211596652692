<template>
    <div class="page">
        <div class="container container--small">
            <div class="page_content">
                <div class="page_content__row">
                    <div class="page_content__col">
                        <div class="page_title">Terms of service</div>
                        <div class="page_text" v-html="termsContent" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import '@/assets/css/page.scss';
import { useMeta } from 'vue-meta'
import axios from 'axios';
import { marked } from 'marked';

export default {
    name: "Terms",
    setup () {
        useMeta({ title: 'Terms of service' })
    },
    data: () => ({
        termsContent: ""
    }),
    created() {
        const renderer = {
            link(href, title, text) {
                return `<a target="_blank" href="${href}" title="${title}" class="page_text_link">${text}</a>`;
            },
            heading(text, number) {
                if (number === 1)
                    return `<div class="page_text_title">${text}</div>`
                else
                    return `<h${number}>${text}</h${number}>`
            }
        }
        marked.use({renderer})

        axios.get("/content/terms.txt").then(res => this.termsContent = marked.parse(res.data))
    }
}
</script>